import Features from "components/common/Features";
import HeroLayout2 from "components/common/hero/HeroLayout2";
import InfoSlider from "components/common/InfoSlider";
import TrustedPartnerSection from "components/common/TrustedPartnerSection";
import { CTA_BUTTON_COPY } from "components/data/landing-page-data";
import IndustryFeaturedUpdated from "components/industries/updated/IndustryFeaturedUpdated";
import GlobalLayout from "components/page/GlobalLayout";
import { graphql, StaticQuery } from "gatsby";
import React from "react";
import { Helmet } from "react-helmet";

export default function SurveillanceA() {
  const renderContent = data => {
    const pageData = {
      header: {
        title: "Smart Surveillance for All Your Business Properties",
        paragraph:
          "Monitor all locations, automatically identify security events, and resolve incidents in real time with Rhombus' AI and Cloud-based solution.",
        formId: "fa7fb89b-ef66-4638-aa6e-e35b012fc6c6",
        image: data.headerImage,
        mobileImage: data.headerImageMobile,
        theme: "light",
      },
      industryLogos: [
        {
          img: data.logo1,
          width: "118px",
          alt: "Amazon",
        },
        {
          img: data.logo2,
          width: "130px",
          alt: "Hurley",
        },
        {
          img: data.logo3,
          width: "177px",
          alt: "Aramark",
        },
        {
          img: data.logo4,
          width: "115px",
          alt: "Stryker",
        },
        {
          img: data.logo5,
          width: "149px",
          alt: "Goodwill",
        },
        {
          img: data.logo6,
          width: "137px",
          alt: "Clark Contruction",
        },
      ],
      infoSlides: [
        {
          tabTitle: "Smart Cameras",
          title: "Protect What Matters Most with Powerful Camera Solutions",
          description:
            "Rhombus suite of dome, fisheye, and bullet cameras deliver exceptional performance and reliability for businesses of all sizes. Capture, detect, and manage security events from a single pane of glass.",
          image: data.infoSlide1,
        },
        {
          tabTitle: "AI Analytics",
          title: "Stay in the Know with Real-Time, AI-Powered Smart Alerts",
          description:
            "Save time, accelerate investigations, and receive real-time intelligent alerts with Rhombus AI Analytics. Through machine-learning, the system automatically learns what ‘normal’ looks like in your spaces and becomes smarter and more effective.",
          image: data.infoSlide2,
        },
        {
          tabTitle: "Environmental Monitoring",
          title: "Create Healthier Spaces and Manage Climate-Controlled Areas",
          description:
            "Continuously monitor air quality, detect vape, and manage temperature, humidity, and more with integrated IoT sensors. Track and gain insight into environmental conditions with real-time alerts and synchronized video.",
          image: data.infoSlide3,
        },
        {
          tabTitle: "Access Control",
          title: "Unify Building Management Like Never Before",
          description:
            "Pair seamlessly with the Rhombus Platform for all-in-one security at scale. Manage all aspects of your spaces from a single pane of glass to streamline operations, eliminate complexity, and improve safety.",
          image: data.infoSlide4,
        },
        {
          tabTitle: "Alarm Monitoring",
          title:
            "Protect Your Spaces Around the Clock with Professional Monitoring",
          description:
            "Smart alarms, real agents. Protect your buildings 24/7/365 with modern alarm monitoring that combines automatic security alerts with live monitoring services.",
          image: data.infoSlide5,
        },
      ],
      features: [
        {
          icon: data.icon1,
          iconAlt: "User-Friendly",
          title: "User-Friendly",
          p:
            "Rhombus combines consumer-level ease of use with enterprise-grade performance, no technical expertise required.",
        },
        {
          icon: data.icon2,
          iconAlt: "AI Analytics",
          title: "AI Analytics",
          p:
            "Rhombus AI uses machine learning to save time, accelerate investigations, and send real-time intelligent alerts.  ",
        },
        {
          icon: data.icon3,
          iconAlt: "Bandwidth & Latency",
          title: "Bandwidth & Latency",
          p:
            "Get the best performance with 50% less bandwidth utilization and 10x better latency compared to other cloud vendors.",
        },
        {
          icon: data.icon4,
          iconAlt: "Infinite Scalability",
          title: "Infinite Scalability",
          p:
            "Scale geographically and vertically within your organization with unlimited devices, locations, and users.",
        },
      ],
    };

    return (
      <GlobalLayout color="var(--gradient-light)" landingPage>
        <Helmet>
          <title>Security + Surveillance Unified | Rhombus™</title>
          <meta
            name="description"
            content="Stay in the know from anywhere at anytime. Automated alerts and surveillance monitoring ensures your spaces, assets, and people are protected at all times."
          />
          <meta name="robots" content="noindex, nofollow" />
        </Helmet>
        <HeroLayout2
          data={pageData.header}
          theme={pageData.header.theme}
          formTitle={CTA_BUTTON_COPY}
          buttonText={CTA_BUTTON_COPY}
          width="953px"
          height="600px"
        />
        <IndustryFeaturedUpdated
          title="Trusted by Industry Leaders"
          logos={pageData.industryLogos}
          color="var(--white)"
        />
        <InfoSlider
          data={pageData.infoSlides}
          title="An All-in-One Platform"
          button
          color="var(--nuetral-100)"
        />
        <Features
          data={pageData.features}
          title="What Sets Rhombus Apart"
          color="var(--white)"
        />
        <TrustedPartnerSection color="var(--nuetral-100)" />
      </GlobalLayout>
    );
  };
  const query = graphql`
    query {
      headerImage: file(
        relativePath: { eq: "components/demo/img/free-trial-header-min.png" }
      ) {
        childImageSharp {
          gatsbyImageData(
            layout: FULL_WIDTH
            quality: 100
            placeholder: BLURRED
          )
        }
      }
      headerImageMobile: file(
        relativePath: { eq: "components/demo/img/free-trial-header-min.png" }
      ) {
        childImageSharp {
          gatsbyImageData(
            layout: FULL_WIDTH
            quality: 100
            placeholder: BLURRED
          )
        }
      }
      infoSlide1: file(
        relativePath: {
          eq: "components/landing-pages/search/img/smart-cameras-min.png"
        }
      ) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, quality: 100)
        }
      }
      infoSlide2: file(
        relativePath: { eq: "components/demo/img/proactive-alerts-min.png" }
      ) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, quality: 100)
        }
      }

      infoSlide3: file(
        relativePath: {
          eq: "components/sensors/environment-monitoring/img/e2-min.png"
        }
      ) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, quality: 100)
        }
      }
      infoSlide4: file(
        relativePath: {
          eq: "components/landing-pages/search/img/access-control-min.png"
        }
      ) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, quality: 100)
        }
      }
      infoSlide5: file(
        relativePath: {
          eq: "components/alarms/updated/img/alert-verification-min.png"
        }
      ) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, quality: 100)
        }
      }
      logo1: file(relativePath: { eq: "components/common/logos/amazon.png" }) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED)
        }
      }
      logo2: file(relativePath: { eq: "components/common/logos/hurley.png" }) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED)
        }
      }
      logo3: file(
        relativePath: { eq: "components/common/logos/aramark-black.png" }
      ) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED)
        }
      }
      logo4: file(relativePath: { eq: "components/common/logos/stryker.png" }) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED)
        }
      }
      logo5: file(
        relativePath: { eq: "components/common/logos/goodwill-2-min.png" }
      ) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED)
        }
      }
      logo6: file(
        relativePath: {
          eq: "components/common/logos/clark-construction-black.png"
        }
      ) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED)
        }
      }
      icon1: file(
        relativePath: { eq: "components/industries/img/icons/system.png" }
      ) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, quality: 100)
        }
      }
      icon2: file(
        relativePath: { eq: "components/industries/img/icons/learning.png" }
      ) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, quality: 100)
        }
      }
      icon3: file(
        relativePath: { eq: "components/industries/img/icons/efficient.png" }
      ) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, quality: 100)
        }
      }
      icon4: file(
        relativePath: { eq: "components/industries/img/icons/location.png" }
      ) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, quality: 100)
        }
      }
    }
  `;
  return <StaticQuery query={query} render={renderContent} />;
}
